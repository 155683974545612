<template>
  <div id="content">
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.legal_notices') }}</h1>
      </header>

      <div v-if="getLocale() === 'fr'">
        <p class="card-text">
          Conformément aux dispositions de l’article 6 III-1 de la Loi n°2004-575 du 21 juin
          2004 pour la Confiance dans l’Economie Numérique, nous vous informons que :
        </p>
        <ul>
          <li class="mb-2">
            Le présent site est édité par La Société FIDUCIAL Cloud, société par actions
            simplifiée, au capital social de 1.249.597,16 euros, dont le siège social est situé 41, rue du
            Capitaine Guynemer – 92400 COURBEVOIE, immatriculée au Registre du Commerce et des Sociétés de
            NANTERRE sous le numéro 480 650 712, et dont le numéro de TVA intracommunautaire est le
            FR06 480 650 712, joignable aux coordonnées suivantes : par e-mail à l’adresse suivante :
            contact@fiducialcloud.fr ou par téléphone au numéro suivant : 04.72.20.76.00.
          </li>
          <li class="mb-2">
            L’hébergeur du site est la société FIDUCIAL Cloud dont le siège social est situé
            au 41, rue du Capitaine Guynemer à Courbevoie (92400), joignable aux coordonnées suivantes: 04.72.20.76.00.
          </li>
          <li class="mb-2">
            Le directeur de la publication du site est Monsieur Jean-Claude CARQUILLAT en
            qualité de représentant permanent de la société FIDUCIAL SC,
            président de la société FIDUCIAL Technologies et de la société FIDUCIAL Cloud.
          </li>
          <li class="mb-2">
            Le numéro de déclaration CNIL de FIDUCIAL Cloud est le 1900841.
          </li>
        </ul>
      </div>

      <div v-if="getLocale() === 'en'">
        <p class="card-text">
          In accordance with the provisions of Article 6 III-1 of the French Law No. 2004-575 of 21 June 2004
          on Confidence in the Digital Economy, we inform you that:
        </p>
        <ul>
          <li class="mb-2">
            This Site is published by the company FIDUCIAL Cloud, Société par Actions simplifiée
            (simplified joint-stock company), with a share capital of 1.249.597,16 euros, whose registered office
            is located at 41, rue du Capitaine Guynemer – FR-92400 COURBEVOIE, France, registered in the NANTERRE
            (France) Trade and Companies Register under number 480 650 712, and whose intra-community VAT number
            is FR06 480 650 712. FIDUCIAL Cloud can be reached as follows: by e-mail at the address:
            contact@fiducialcloud.fr; or by telephone at the number: +33.(0)4.72.20.76.00.
          </li>
          <li class="mb-2">
            The Site is hosted by the company FIDUCIAL Cloud whose registered office is located at
            41, rue du Capitaine Guynemer in FR-92400 COURBEVOIE, France, which can be reached by telephone
            at number: +33.(0)4.72.20.76.00.
          </li>
          <li class="mb-2">
            The director of the publication of the Site is Mr. Jean-Claude CARQUILLAT in his capacity of permanent
            representative of the company FIDUCIAL SC, president of the companies FIDUCIAL Technologies
            and FIDUCIAL Cloud.
          </li>
          <li class="mb-2">
            FIDUCIAL Cloud’s registration number with the French CNIL is 1900841.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'LegalNotice',
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Mentions légales'
  },
  methods: {
    getLocale () {
      return localStorage.getItem('locale') || 'fr'
    }
  }
}
</script>
